import React, { useState } from "react";
import { useForm } from "react-hook-form";
import type { FieldValues } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useTranslations } from "../../queries";

import { checkValidation } from "../../hooks/formValidations";
import { detailsForm } from "../../utils/otsConstants";
import EventBus from "core-ui/client/src/app/core/eventbus/EventBus";
import AccountOpenEvents from "./events/AccountOpenEvents";
import { AMPLITUDE_EVENTS } from "core-ui/client/src/app/core/amplitude";
import useStateCodes from "../../queries/useStateCodeDetails/useStateCodeDetails";

interface FormData {
    addressLine1: string;
    addressLine2: string;
    city: string;
    context: string;
    dateOfBirth: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    ssn: string;
    state: string;
    zipCode: string;
}

interface AccountOpenDetailsFormProps {
    hasReferrerParam: boolean;
    state: any;
}

const submitButtonStyles = {
    disabled:
        "continue btn btn-primary btn-default__accessible btn-lg btn-block margin-top-default outline-btn is-disabled",
    enabled: "continue btn btn-primary btn-lg btn-block margin-top-default outline-btn"
};

export interface AccountOpenTranslations {
    accountOpen: {
        detailsForm: string;
    };
    button: { continue: string };
}
const EXCLUDED_STATE_CODES = [
    "AB",
    "BC",
    "BU",
    "FM",
    "LB",
    "MB",
    "MP",
    "NB",
    "NF",
    "NL",
    "NS",
    "NT",
    "NU",
    "ON",
    "PE",
    "PW",
    "QC",
    "SK",
    "WB",
    "YT"
];
const REFERRAL = "referral";
const OTS = "ots";
const INIT_ACCOUNT_OPEN_DETAILS_FORM_DATA = {
    firstName: "",
    lastName: "",
    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    zipCode: "",
    ssn: "",
    dateOfBirth: "",
    phoneNumber: "",
    context: ""
};

const AccountOpenDetailsForm = ({ hasReferrerParam, state }: AccountOpenDetailsFormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid }
    } = useForm<FieldValues>({
        mode: "onBlur"
    });

    const [formData, updateFormData] = useState<FormData>(INIT_ACCOUNT_OPEN_DETAILS_FORM_DATA);
    const { accountOpen, button } = useTranslations<AccountOpenTranslations>();
    const navigate = useNavigate();
    const eventBus = new EventBus(window, document);
    const { data: stateData, isLoading: stateDataLoading } = useStateCodes();

    const stateCodes = stateData?.filter((code) => !EXCLUDED_STATE_CODES.includes(code.code));

    const handleFormSubmit = (data) => {
        updateFormData(data);
        if (state) {
            //TODO:This will be removed once angular components completely migrated to react
            state.go("otsAccountRegistrationB", { formData: data });
        } else {
            navigate("/accountOpenPersonalDetails", { state: { formData: data } });
        }
        eventBus.dispatchAmplitude({
            event_type: AMPLITUDE_EVENTS.SELECT_BUTTON,
            event_properties: {
                selection: AccountOpenEvents.PRE_ENROLL_FORM_2_NEXT
            }
        });
    };

    const getStateNameByCode = (code) => {
        const state = stateCodes?.find((state) => state.code === code);
        return state?.name || null;
    };

    const key = hasReferrerParam ? REFERRAL : OTS;
    return (
        <div>
            {stateDataLoading && (
                <div className="loaderBackground">
                    <div className="loader"></div>
                </div>
            )}
            <div className="container row" data-testid="account-open-details-form">
                <div className="row">
                    <div className="registration col-xs-7 col-xs-offset-5 container-pad">
                        <header className="contained-form-header margin-top-default">
                            <h1 id="title" className="ots-prelogin-header" role="heading">
                                {accountOpen[key].title}
                            </h1>
                        </header>

                        <div className="tab-content">
                            <div role="tabpanel" className="tab-pane active">
                                <p id="description description-adjust">
                                    {accountOpen[key].description1}
                                </p>
                                <form onSubmit={handleSubmit(handleFormSubmit)}>
                                    <div className="row">
                                        {detailsForm.map(
                                            ({ info, size, label, defaultValue }, idx) => (
                                                <div key={info} className={size}>
                                                    <div
                                                        className={`form-group ${
                                                            errors[info]
                                                                ? "has-error error-block"
                                                                : ""
                                                        }`}
                                                    >
                                                        <label htmlFor={info}>
                                                            <span
                                                                className={`form-group ${
                                                                    errors[info]
                                                                        ? "has-error error-block"
                                                                        : ""
                                                                }`}
                                                            >
                                                                {accountOpen.detailsForm[info]}
                                                            </span>
                                                        </label>
                                                        {info === "state" ? (
                                                            <select
                                                                autoFocus={idx === 0}
                                                                className={"form-control"}
                                                                id={info}
                                                                {...register(
                                                                    info,
                                                                    checkValidation(info, label)
                                                                )}
                                                                data-testid="account-open-form-field-input-state"
                                                            >
                                                                <option key="blank" value={""}>
                                                                    {getStateNameByCode(
                                                                        formData[info]
                                                                    ) || defaultValue}
                                                                </option>
                                                                {stateCodes &&
                                                                    stateCodes.map((op) => (
                                                                        <option
                                                                            key={op.code}
                                                                            value={op.code}
                                                                        >
                                                                            {op.name}
                                                                        </option>
                                                                    ))}
                                                            </select>
                                                        ) : (
                                                            <input
                                                                autoFocus={idx === 0}
                                                                type="text"
                                                                className={`form-control ${
                                                                    errors[info]
                                                                        ? "error has-error"
                                                                        : ""
                                                                }`}
                                                                id={info}
                                                                data-testid={`account-open-form-field-input-${info}`}
                                                                {...register(
                                                                    info,
                                                                    checkValidation(info, label)
                                                                )}
                                                            />
                                                        )}
                                                        {errors[info] && (
                                                            <div className="messages">
                                                                <p
                                                                    aria-live="assertive"
                                                                    className="error-block msg"
                                                                >
                                                                    {errors[
                                                                        info
                                                                    ]?.message?.toString()}
                                                                </p>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )
                                        )}
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12">
                                            <button
                                                type="submit"
                                                aria-disabled={!isValid}
                                                className={
                                                    isValid
                                                        ? submitButtonStyles.enabled
                                                        : submitButtonStyles.disabled
                                                }
                                            >
                                                {button.continue}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccountOpenDetailsForm;
