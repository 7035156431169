import React, { useState, useEffect, useCallback } from "react";

import { useForm } from "react-hook-form";
import type { FieldValues } from "react-hook-form";
import MaskedInput from "react-text-mask";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslations } from "../../queries";

import { checkValidation } from "../../hooks/formValidations";
import { personalDetailsForm, routeDestination } from "../../utils/otsConstants";
import OtsAccountModal from "../shared/OtsAccountModal";
import { getExistingUserOTS } from "./services/getExistingUserOTS";

interface AccountOpenPersonalDetailsFormProps {
    state: { go: (path: string) => void };
    stateParam: {
        formData: {
            addressLine1: string;
            addressLine2: string;
            city: string;
            dateOfBirth: string;
            firstName: string;
            lastName: string;
            ssn: string;
            state: string;
            zipCode: string;
        };
    };
}

interface AccountOpenPersonalDetailTranslations {
    accountOpen: {
        description2: string;
        detailsForm: string;
        title: string;
    };
    button: { continue: string };
    logon: {
        labels: {
            hidePassLabel: string;
            showPassLabel: string;
        };
    };
}

const AccountOpenPersonalDetailsForm = ({
    state,
    stateParam
}: AccountOpenPersonalDetailsFormProps) => {
    const {
        register,
        handleSubmit,
        formState: { errors, isValid },
        setValue
    } = useForm<FieldValues>({
        mode: "onBlur"
    });
    const location = useLocation();
    const [showSSN, setShowSSN] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [isRegistered, setIsRegistered] = useState(false);
    const [accountOpenFormData, setOpenAccountFormData] = useState({});
    const prevFormData = stateParam?.formData ? stateParam.formData : location?.state?.formData;

    const hasSkipExperian = new URLSearchParams(window.location.href).get("skipExperian");
    const hasReferrerParam = new URLSearchParams(window.location.search).get("referrerguid") || "";
    const { accountOpen, logon, button } = useTranslations<AccountOpenPersonalDetailTranslations>();
    const navigate = useNavigate();
    useEffect(() => {
        if (prevFormData) {
            setOpenAccountFormData(prevFormData);
        }
    }, [prevFormData]);

    const handleFieldChange = (e) => {
        const { name, value, selectionStart } = e.target;
        const cleanedValue = value.replace(/\D/g, "");
        const formattedDate = cleanedValue
            .slice(0, 8)
            .replace(/(\d{2})(\d{0,2})(\d{0,4})/, (_, p1, p2, p3) => {
                let formattedValue = "";
                if (p1) {
                    formattedValue += p1;
                }

                if (p2) {
                    formattedValue += "/" + p2;
                }

                if (p3) {
                    formattedValue += "/" + p3;
                }
                return formattedValue;
            });
        let newPosition =
            selectionStart +
            (formattedDate.length - value.length) +
            (formattedDate.charAt(selectionStart - 1) === "/" ? 1 : 0);

        if (
            (formattedDate.charAt(selectionStart - 1) === "/" && newPosition === 4) ||
            (formattedDate.charAt(selectionStart - 1) === "/" && newPosition === 7)
        ) {
            newPosition = newPosition - 1;
        }
        setValue(name, formattedDate);

        e.target.setSelectionRange(newPosition, newPosition);
    };

    const handleSSNChange = (e) => {
        const { name, value } = e.target;

        const unformmattedValue = value.replace(/-/g, "");
        const formattedValue = unformmattedValue
            .replace(/\D/g, "")
            .replace(/(\d{3})(\d{2})(\d{4})/, `$1-$2-$3`);

        setValue(name, formattedValue);
    };

    const handlePersonalDetailsFormSubmit = async (data) => {
        const updatedData = { ...accountOpenFormData, ...data };
        handleRoutingOption(updatedData);
    };

    const userRegistrationInformation = (updatedData) => {
        const {
            ssn,
            firstName,
            lastName,
            addressLine1,
            addressLine2,
            city,
            state,
            zipCode,
            dateOfBirth
        } = updatedData;
        const formatDOB = dateOfBirth.split("/").join("");
        const formatSSN = ssn.split("-").join("");

        const userInfo = {
            ssn: formatSSN,
            zipCode: zipCode,
            lastName: lastName,
            dateOfBirth: formatDOB,
            firstName: firstName,
            addressLine1: addressLine1,
            addressLine2: addressLine2,
            city: city,
            stateCode: state,
            country: "US"
        };

        return userInfo;
    };
    const handleRoutingOption = (updatedData) => {
        let skipExperian = "";
        let referrerParam = "";

        if (hasSkipExperian !== null) {
            skipExperian = `?skipExperian=${skipExperian}`;
        }
        if (hasReferrerParam) {
            referrerParam = `${skipExperian ? "&" : "?"}referrerGuid=${hasReferrerParam}`;
        }
        const registeredInfo = userRegistrationInformation(updatedData);
        getExistingUserOTS(registeredInfo, skipExperian, referrerParam)
            .then(function (response) {
                if (response) {
                    if (response.errors === false) {
                        if (response.state === "ID_PROOFING_OTP_STATE") {
                            if (state) {
                                //TODO:This will be removed once angular components completely migrated to react
                                state.go("otpIdentityVerification");
                            } else {
                                navigate("/idProof/otp-verify", { replace: true });
                            }
                        } else if (response.state === "REGISTER_USER") {
                            setIsRegistered(false);
                        } else if (response.state === "LOGIN_USER") {
                            setIsRegistered(true);
                        } else if (response.state === "OTS_ACCOUNT_SETUP") {
                            //Todo: Need to integrate redirective service
                        }
                    }
                }
            })
            .catch(function (error) {
                console.log("An Error occured: " + error);
            });
    };
    /* Modal functions */
    const handleOpenModal = useCallback(() => {
        if (isRegistered) {
            setShowModal(true);
        } else {
            setShowModal(false);
        }
    }, [isRegistered]);

    useEffect(() => {
        if (isRegistered !== null) {
            handleOpenModal();
        }
    }, [isRegistered, handleOpenModal]);

    const handleCloseModal = () => {
        setShowModal(false);

        if (isRegistered) {
            // reroute to prelogin
            window.location.href = routeDestination.retailRegistered;
        } else {
            // reroute to registration
            window.location.href = routeDestination.retailUnregistered;
        }
    };

    const handleClick = (e) => {
        if (e.type === "click" || (e.type === "keyup" && e.code === "Enter")) {
            e.stopPropagation();
            e.preventDefault();
            setShowSSN(!showSSN);
        }
    };

    const submitButtonStyles = {
        disabled:
            "continue btn btn-primary btn-default__accessible btn-lg btn-block margin-top-default outline-btn is-disabled",
        enabled: "continue btn btn-primary btn-lg btn-block margin-top-default outline-btn"
    };
    return (
        <div>
            <div className="container row" data-testid="account-open-personal-details-form">
                <div className="row">
                    <div className="registration col-xs-7 col-xs-offset-5 container-pad">
                        <header className="contained-form-header margin-top-default">
                            <h1 id="title" className="ots-prelogin-header" role="heading">
                                {accountOpen.title}
                            </h1>
                        </header>

                        <div className="tab-content hidden-xs">
                            <div role="tabpanel" className="tab-pane active">
                                <form onSubmit={handleSubmit(handlePersonalDetailsFormSubmit)}>
                                    <div className="row">
                                        <p id="description">{accountOpen.description2}</p>
                                        {personalDetailsForm.map(({ info, size, label }, idx) => (
                                            <div
                                                key={info}
                                                className={[
                                                    "form-group",
                                                    errors[info] && "has-error",
                                                    size
                                                ].join(" ")}
                                            >
                                                <label htmlFor={info} className="control-label">
                                                    {accountOpen.detailsForm[info]}
                                                </label>
                                                {info === "ssn" ? (
                                                    <div className="input-group">
                                                        <MaskedInput
                                                            autoFocus={idx === 0}
                                                            className="form-control"
                                                            tabIndex={0}
                                                            mask={[
                                                                /\d/,
                                                                /\d/,
                                                                /\d/,
                                                                "-",
                                                                /\d/,
                                                                /\d/,
                                                                "-",
                                                                /\d/,
                                                                /\d/,
                                                                /\d/,
                                                                /\d/
                                                            ]}
                                                            guide={false}
                                                            type={showSSN ? "text" : "password"}
                                                            id={info}
                                                            {...register(
                                                                info,
                                                                checkValidation(info, label)
                                                            )}
                                                            onChange={handleSSNChange}
                                                            data-testid="account-open-form-field-input-ssn"
                                                        />
                                                        <div className="input-group-addon">
                                                            <span
                                                                className={[
                                                                    "fa-regular",
                                                                    showSSN
                                                                        ? "fa-eye"
                                                                        : "fa-eye-slash"
                                                                ].join(" ")}
                                                                role="button"
                                                                aria-label={
                                                                    showSSN
                                                                        ? logon.labels.hidePassLabel
                                                                        : logon.labels.showPassLabel
                                                                }
                                                                tabIndex={0}
                                                                onClick={handleClick}
                                                                onKeyUp={handleClick}
                                                            ></span>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        autoFocus={idx === 0}
                                                        id={info}
                                                        tabIndex={0}
                                                        data-testid="account-open-form-field-input-dob"
                                                        {...register(
                                                            info,
                                                            checkValidation(info, label)
                                                        )}
                                                        onChange={handleFieldChange}
                                                    />
                                                )}
                                                {errors[info] && (
                                                    <div className="messages">
                                                        <p
                                                            aria-live="assertive"
                                                            className="msg error-block"
                                                            data-testid="account-open-personal-details-form-error"
                                                        >
                                                            {errors[info]?.message?.toString()}
                                                        </p>
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                        <div className="row">
                                            <div className="col-xs-12">
                                                <button
                                                    type="submit"
                                                    aria-disabled={!isValid}
                                                    className={
                                                        isValid
                                                            ? submitButtonStyles.enabled
                                                            : submitButtonStyles.disabled
                                                    }
                                                >
                                                    {button.continue}
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <OtsAccountModal
                                    show={showModal}
                                    handleClose={handleCloseModal}
                                    translations={useTranslations()}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AccountOpenPersonalDetailsForm;
