import contactInfoTemplate from "../accountSetup/contactInfoTemplate";
import registerCredentialTemplate from "../accountSetup/registerCredentialTemplate";

const npdiAccountSetupTemplate = `
<div class="alert alert-danger" data-ng-if="errors.length > 0">
    <p data-ng-repeat="error in errors">{{error}}</p>
</div>

<div class="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 registration">
    <header class="contained-form-header">
        <h1>
             {{ "accountSetup.setUpAccountTitle" | translate }}
        </h1>
        <p>{{ "accountSetup.completeRegistrationInfo" | translate }}</p>
    </header>

    <div class="inner-container with-padding with-shadow">
        <form role="form" id="registration-form" name="accountSetupForm" class="clearfix" data-ng-init="retrieveContactDetailsDropdown()" data-ng-submit="setUpAccount()" novalidate autocomplete="off">
            <h2> 
             {{ "accountSetup.contactInformationTitle" | translate }}
            </h2>

            <div class="form-group has-error">
                <span class="help-block" ng-bind-html="saveStatus | translate:saveStatusParams"></span>
            </div>
            ${contactInfoTemplate}

            <hr/>
			
            <h2 data-ng-if="pecbypassregEnabled==='false'"> 
               {{ "accountSetup.usernamePasswordTitle" | translate }}
            </h2>

            <div data-ng-if="pecbypassregEnabled==='false'">
                ${registerCredentialTemplate}
            </div>

            <div class="form-group clearfix">
                <button 
                    type="submit"
                    id="submit"
                    class="btn btn-primary btn-block btn-lg margin-top-default" 
                    ng-disabled="showSpinner==true"
                >
                    <span data-ng-hide="showSpinner==true" data-ng-if="pecbypassregEnabled==='false'">
                        {{ "button.register" | translate }}
                    </span>
                    <span data-ng-hide="showSpinner==true" data-ng-if="pecbypassregEnabled==='true'">
                        {{ "button.continue" | translate }}
                    </span>
                                        
                    <span data-ng-show="showSpinner==true">
                        <i class="fa fa-circle-o-notch fa-spin"></i>
                        Processing ...
                    </span>
                </button>       
            </div>
        </form>
    </div>
</div>
`;
export default npdiAccountSetupTemplate;
